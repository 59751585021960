// serviceRegistration.jsx

import React, { useState } from "react";
import "../calendar.css";
import ReactModal from "react-modal";
import axios from "axios";

ReactModal.setAppElement("#root");

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0]; // Returns date in YYYY-MM-DD format
};

// ServiceRegistrationForm component
const ServiceRegistrationForm = ({
  formData,
  handleChange,
  handleSubmit,
  toggleModalVisibility,
  errorMessage,
}) => {
  return (
    <div className="bg-white shadow-lg rounded-lg px-10 py-8 mb-6 border-2 border-gray-600">
      <div className="mb-8">
        <h2 className="text-gray-900 text-2xl font-semibold text-center mb-6">
          Service Registration
        </h2>
        <p className="text-center text-gray-600 mb-6 font-medium">
          Service Type:{" "}
          <span className="text-gray-800">{formData.serviceType}</span>
        </p>
      </div>
      <form
        onSubmit={handleSubmit}
        className="bg-white rounded px-8 pt-6 pb-8"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-semibold mb-2"
            htmlFor="serviceDate"
          >
            Service Date
          </label>
          <input
            className="border-gray-600 shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-joule focus:ring-1 focus:ring-joule"
            id="serviceDate"
            type="date"
            name="serviceDate"
            value={formData.serviceDate}
            onChange={handleChange}
          />
        </div>

        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-semibold mb-2"
            htmlFor="serialNumber"
          >
            Serial Number
          </label>
          <input
            className="border-gray-600 shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-joule focus:ring-1 focus:ring-joule"
            id="serialNumber"
            type="text"
            name="serialNumber"
            value={formData.serialNumber}
            onChange={handleChange}
          />
        </div>

        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-semibold mb-2"
            htmlFor="km"
          >
            KM
          </label>
          <input
            className="border-gray-600 shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-joule focus:ring-1 focus:ring-joule"
            id="km"
            type="number"
            name="km"
            value={formData.km}
            onChange={handleChange}
          />
        </div>

        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-semibold mb-2"
            htmlFor="note"
          >
            Note
          </label>
          <textarea
            className="border-gray-600 shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-joule focus:ring-1 focus:ring-joule"
            id="note"
            name="note"
            value={formData.note}
            onChange={handleChange}
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
          <button
            className="bg-joule text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-gray-900 focus:outline-none focus:shadow-outline transition-colors"
            type="submit"
          >
            Register Service
          </button>
          <button
            className="bg-red-500 text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:shadow-outline transition-colors"
            type="button"
            onClick={toggleModalVisibility}
          >
            Cancel
          </button>
        </div>
        {/* Error Message Display */}
        {errorMessage && (
          <div className="mt-4 text-red-600">{errorMessage}</div>
        )}
      </form>
    </div>
  );
};

// ServiceCell component
const ServiceCell = ({
  isOpen,
  data,
  source,
  toggleModalVisibility,
  markAsRepaired,
}) => {
  // Determine the serviceType based on the source and type
  let serviceType = "";
  if (source === "returns") {
    serviceType = "Return Service";
  } else if (source === "orders") {
    serviceType = "Prepare Service";
  } else if (source === "bookings") {
    serviceType = data.type === "Service" ? "Booked Service" : "Booked Repair";
  }

  const [formData, setFormData] = useState({
    serviceDate: getCurrentDate(),
    serialNumber: data.serialNumber || "",
    km: data.km || "",
    note: data.note || "",
    serviceType,
  });

  // Add a new state to store error messages
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Reset error message on new submission

    try {
      // Payload for inventory operational status update
      let operationalStatusPayload = {};

      if (source === "returns") {
        operationalStatusPayload = { operationalStatus: "in stock" };
      } else if (source === "orders" || source === "bookings") {
        operationalStatusPayload = { operationalStatus: "ready for customer" };
      }

      // First, update the inventory item for operational status
      if (operationalStatusPayload.operationalStatus) {
        await axios.put(
          `/api/inventory/${formData.serialNumber}/operationalStatus`,
          operationalStatusPayload
        );

        // Payload for inventory service update
        const servicePayload = {
          lastService: formData.serviceDate,
          lastKm: parseInt(formData.km, 10),
        };

        // Register the service
        await axios.post("/api/services", formData);

        // SMS sending logic for bookings
        if (source === "bookings") {
          const smsData = {
            number: data.number, // Assuming number is part of the data object
            bookingId: data._id, // The booking ID from the original booking
          };

          try {
            await axios.post("/api/send-service-ready-sms", smsData);
          } catch (smsError) {
            // Log or handle SMS sending error without blocking the main flow
            console.error("Failed to send service completion SMS:", smsError);
          }
        }

        // Update the inventory item for service details
        await axios.put(
          `/api/inventory/${formData.serialNumber}`,
          servicePayload
        );

        // Additional API calls based on source
        if (source === "returns") {
          await axios.post(`/api/set-in-stock/${formData.serialNumber}`);
        }

        if (source === "orders") {
          await axios.put(`/api/circuly-orders/${data.orderId}/tag`, {
            tag: "waiting for dispatch",
          });
        }

        // Attempt to mark as repaired
        await markAsRepaired(data._id);
      }

      // Close the modal
      toggleModalVisibility();
    } catch (error) {
      // Set error message to be displayed
      setErrorMessage(`Error: ${error.response?.data?.error || error.message}`);
    }
  };

  // Render a modal containing the registration form
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={toggleModalVisibility}
      contentLabel="Service Registration"
      className="modal-content"
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick={true}
    >
      <ServiceRegistrationForm
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        toggleModalVisibility={toggleModalVisibility}
        errorMessage={errorMessage}
      />
    </ReactModal>
  );
};

export default ServiceCell;
